import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Accordion, Notification } from 'hds-react';
import LeadParagraph from '../../../components/LeadParagraph';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "server-side-rendering",
      "style": {
        "position": "relative"
      }
    }}>{`Server-side rendering`}<a parentName="h1" {...{
        "href": "#server-side-rendering",
        "aria-label": "server side rendering permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  The server-side rendering support of the Helsinki Design System makes the user's landing experience to pages smooth without
  flashes of unstyled content.
    </LeadParagraph>
    <h2 {...{
      "id": "what-is-server-side-rendering",
      "style": {
        "position": "relative"
      }
    }}>{`What is server-side rendering?`}<a parentName="h2" {...{
        "href": "#what-is-server-side-rendering",
        "aria-label": "what is server side rendering permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Server-side rendering (SSR) is the process where the rendering of HTML pages is done on the server-side. The fully rendered HTML
document is then sent to the browser. The rendering of the HTML can happen at build time (Static site generation
or pre-rendering), or during an HTTP request. The alternative to SSR is client-side rendering (CSR), where most of the HTML
content is composed and rendered in the browser using JavaScript.`}</p>
    <h2 {...{
      "id": "how-does-hds-support-server-side-rendering",
      "style": {
        "position": "relative"
      }
    }}>{`How does HDS support server-side rendering?`}<a parentName="h2" {...{
        "href": "#how-does-hds-support-server-side-rendering",
        "aria-label": "how does hds support server side rendering permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`For HDS components to work with server-side rendering, you need to inject the critical CSS styles of the HDS components
that are being used into the initially rendered HTML on the server's side. For hds-core, you must include the styles from
the provided CSS files yourself. For hds-react HDS provides multiple options, which we will cover next.`}</p>
    <Notification label="Critical CSS" className="siteNotification" mdxType="Notification">
Critical CSS is applied to above-the-fold elements. It provides the styles for the immediately visible content in the browser
viewport when the user opens your website. Critical CSS does not usually have the styles of the elements that are in the
scrollable content outside of the browser viewport. There is one exception though, and that is if you have anchor links on the page.
When user opens website from an URL that includes an anchor link, the browser automatically scrolls the page so that the link
will be visible.
    </Notification>
    <h3 {...{
      "id": "option-1-getcriticalhdsrules-tool-to-get-critical-styles-recommended",
      "style": {
        "position": "relative"
      }
    }}>{`Option 1: getCriticalHdsRules tool to get critical styles (recommended)`}<a parentName="h3" {...{
        "href": "#option-1-getcriticalhdsrules-tool-to-get-critical-styles-recommended",
        "aria-label": "option 1 getcriticalhdsrules tool to get critical styles recommended permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS React components use CSS-in-JS in a way where the styles are by default injected into the head tag in the browser.
If you use server-side rendering and do not include the critical styles on the server, this leads to flashes of unstyled
content when the user lands on the page.`}</p>
    <p>{`To include critical styles on the server, HDS exposes a tool for extracting the used critical styles of HDS components.
This should come in handy in multiple ways:`}</p>
    <ul>
      <li parentName="ul">{`Automatically extracts styles based on used HTML.`}</li>
      <li parentName="ul">{`If you add more HDS components later, you do not need to remember to add their styles separately.`}</li>
      <li parentName="ul">{`If you happen to remove HDS components, you do not need to remember to remove their respective styles.`}</li>
    </ul>
    <p>{`Let's go through a simple example of the usage of the tool:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { getCriticalHdsRules, hdsStyles } from "hds-react";

const criticalHdsRules = await getCriticalHdsRules(bodyHTML, hdsStyles);

const finalHTML =
<html>
  <head>
    ...
    <style data-used-styles dangerouslySetInnerHTML={{ __html: criticalHdsRules }} />
  </head>
  <body>
    ...
  </body>
<html>
`}</code></pre>
    <p>{`First, we import the tool that is named `}<inlineCode parentName="p">{`getCriticalHdsRules`}</inlineCode>{`. To be able to call it, we need two things:`}</p>
    <ol>
      <li parentName="ol">{`The initially to-be-rendered HTML body code as a string.`}</li>
      <li parentName="ol">{`hdsStyles - a variable holding all the styles of the HDS react components as a string.`}</li>
    </ol>
    <p>{`Calling it returns a string containing the critical CSS styles. The rest is easy, we set those styles into
a style tag, that we will then inject into the finally rendered HTML document. It can be wise to cache the result of
`}<inlineCode parentName="p">{`getCriticalHdsRules`}</inlineCode>{` based on the function parameters in order to improve performance.`}</p>
    <p>{`See below for more complete examples:`}</p>
    <Accordion heading="Next.js" headingLevel="4" language="en" size={"s"} theme={{
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  In the Next.js framework, create a file called _document.js in folder pages, and add this code to it:
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`// pages/_document.js - tested with next.js version: 10.0.5
import Document, { Html, Head, Main, NextScript } from 'next/document'
import { getCriticalHdsRules, hdsStyles } from 'hds-react';

class MyDocument extends Document {
  static async getInitialProps(ctx) {
    const initialProps = await Document.getInitialProps(ctx)
    const hdsCriticalRules = await getCriticalHdsRules(initialProps.html, hdsStyles);

    return {...initialProps, hdsCriticalRules}
  }

  render() {
    return (
      <Html>
        <Head>
          <style data-used-styles dangerouslySetInnerHTML={{ __html: this.props.hdsCriticalRules }} />
        </Head>
        <body>
          <Main />
          <NextScript />
        </body>
      </Html>
    )
  }
}

export default MyDocument
`}</code></pre>
    </Accordion>
    <Accordion heading="Gatsby" headingLevel="4" language="en" size={"s"} theme={{
      '--padding-vertical': 'var(--spacing-s)'
    }} mdxType="Accordion">
  In the Gatsby framework, create a file called gatsby-ssr.js at the root of the project, and add this code to it:
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`// gatsby-ssr.js - tested with gatsby version 4.17.0
import React from 'react';
import { getCriticalHdsRules, hdsStyles } from 'hds-react';
import { renderToString } from 'react-dom/server';

export const replaceRenderer = async ({ bodyComponent, setHeadComponents }) => {
  const bodyHTML = renderToString(bodyComponent);

  if (hdsStyles && hdsStyles.length > 0 && bodyHTML && bodyHTML.length > 0) {
    const cssRules = await getCriticalHdsRules(bodyHTML, hdsStyles);
    const HeadComponents = [
      <style data-used-styles dangerouslySetInnerHTML={{ __html: cssRules }} />
    ];

    return setHeadComponents(HeadComponents);
  }

  return;
}
`}</code></pre>
    </Accordion>
    <h3 {...{
      "id": "option-2-use-indexcss-or-hdsstyles-not-recommended",
      "style": {
        "position": "relative"
      }
    }}>{`Option 2: Use index.css or hdsStyles (not recommended)`}<a parentName="h3" {...{
        "href": "#option-2-use-indexcss-or-hdsstyles-not-recommended",
        "aria-label": "option 2 use indexcss or hdsstyles not recommended permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS react provides all the styles of HDS components in a file called `}<inlineCode parentName="p">{`index.css`}</inlineCode>{`, located at the root of the library.
You can import that file and collect the critical CSS styles from there. Another alternative is to use exported variable
called `}<inlineCode parentName="p">{`hdsStyles`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { hdsStyles } from 'hds-react';
`}</code></pre>
    <p>{`This variable holds all the styles of HDS React components compiled into a single string. It might be tempting to include
all the styles in `}<inlineCode parentName="p">{`hdsStyles`}</inlineCode>{` or `}<inlineCode parentName="p">{`index.css`}</inlineCode>{` to your HTML, and call it a day. This is not optimal because the size of the
HDS styles is large and probably growing as new components are added to it. Adding all the styles might have an impact on the
performance of the app. Instead, you should collect only the necessary styles for the initial render. We recommend using
the tool described in option 1. But if you are unable to use that, extracting the critical CSS styles from either
`}<inlineCode parentName="p">{`hdsStyles`}</inlineCode>{` or `}<inlineCode parentName="p">{`index.css`}</inlineCode>{` might work out for you.`}</p>
    <h3 {...{
      "id": "customising-hds-components-and-server-side-rendering",
      "style": {
        "position": "relative"
      }
    }}>{`Customising HDS components and server-side rendering`}<a parentName="h3" {...{
        "href": "#customising-hds-components-and-server-side-rendering",
        "aria-label": "customising hds components and server side rendering permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`If you customise hds-react components with the `}<code>{`theme`}</code>{` prop, the style changes will not be visible on the
first render. The preferred way to customise hds-react components with server-side rendering is using the `}<code>{`className`}</code>{`
prop.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      